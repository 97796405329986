@keyframes pulse {
    from {
        opacity: .24;
    }
    to {
        opacity: 1;
    }
}


* {
    padding: 0;
    margin: 0;
}

html {
    background: black;
    color: white;
    font-size: 80px;
    height: 100vh;
}

@media (max-width: 1280px) {
    html {
        font-size: 50px;
    }
}

@media (max-width: 720px) {
    html {
        font-size: 30px;
    }
}

body {
    position: relative;
    height: 100vh;
    min-height: 100%;
    overflow: hidden;
}

.container {
    height: 100%;
    min-height: 100%;
    position: relative;
}

.start {
    background: #000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    // top: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // padding: 50vh;
    // transform: translateY(-50%);
    // text-align: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s;
    z-index: -1;

    font-family: 'Toxigenesis', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;

    &.active {
        opacity: 1;
        z-index: 2;
    }

    .title {
        font-size: .6rem;
    }
    .cta, .cta-ios, .loading {
        font-size: .4rem;
    }

    .cta {
        display: none;
        opacity: .36;
    }
    .cta-ios {
        display: none;
        opacity: .36;
    }

    .loading {
        color: #d8262f;
        opacity: .24;
        animation: pulse .8s infinite alternate;
    }
    &.ready .loading,
    &.ready-ios .loading {
        display: none;
    }
    &.ready .cta,
    &.ready-ios .cta-ios {
        display: block;
    }
}

.single-cover {
    width: 24vmax;
    // margin-right: 5vw;
    margin-bottom: 5vw;
}

.header {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 15vmin;
    padding: 1vw 4vw;
    z-index: 2;

    transition: transform 0.3s;
    transform: translateY(-100%);

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.active, &:hover, .start.active + .container & {
        transform: translateY(0);
    }
    .start.active + .container & {
        z-index: 2;
    }

    img {
        display: block;
    }

    img, a {
        height: 35%;
    }

    .logos {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        a > img {
            height: 100%;
        }

        a {
            margin-left: 2vw;
        }
    }
}

.controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 55px;

    font-size: 0;

    transition: transform 0.3s;

    transform: translateY(100%);

    &.active, &:hover, &.seeking {
        transform: translateY(0);
    }

    .timeline {
        position: relative;

        height: 10px;
        width: 100%;

        background: #333;

        cursor: pointer;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: calc(var(--progress) * 100%);
            background: white;
        }
    }

    .buttons {
        height: 45px;
        background: rgba(0, 0, 0, 0.8);

        button {
            position: relative;
            height: 45px;
            width: 60px;

            background: none;
            border: none;

            background-size: 100%;

            cursor: pointer;

            .icon {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background-size: 80%;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0.7;
            }

        }

        .leftCluster {
            float: left;

            .playPause {
                float: left;

                .play {
                    opacity: 1;
                    background-image: url('/icons/play.svg');
                }
                .pause {
                    opacity: 0;
                    background-image: url('/icons/pause.svg');
                }

                &.playing {
                    .play {
                        opacity: 0;
                    }
                    .pause {
                        opacity: 1;
                    }
                }
            }

            .restartVideo {
                float: left;

                .icon.restart {
                    opacity: .8;
                    background-image: url('/icons/restart.svg');
                }
                .icon.restart:hover {
                    opacity: 1;
                }
            }

            .viewsCounter {
                color: #fff;
                font-family: monospace;
                font-weight: bold;
                font-size: .21rem;
                letter-spacing: .5px;
                opacity: .7;


                .icon.views {
                    background-image: url('/icons/views.svg');
                }

                .counter {
                    display: inline-block;
                    margin-left: 60px;
                }

                @media screen and (max-width: 720px) {
                    font-size: .55rem;
                }
                @media screen and (min-width:721px) and (max-width: 1280px) {
                    font-size: .34rem;
                }
            }
        }




        .rightCluster {
            float: right;

            .volume {
                display: inline-block;

                .unmuted {
                    opacity: 1;
                    background-image: url('/icons/volume.svg');
                }
                .muted {
                    opacity: 0;
                    background-image: url('/icons/volume-muted.svg');
                }

                &.muted {
                    .unmuted {
                        opacity: 0;
                    }
                    .muted {
                        opacity: 1;
                    }
                }

                .volumeSliderContainer {
                    position: absolute;
                    display: none;
                    background: rgba(0, 0, 0, 0.8);
                    bottom: 100%;
                    padding: 10px 20px;

                    cursor: default;

                    .volumeSlider {
                        position: relative;

                        width: 20px;
                        height: 160px;
                        background: #333;

                        cursor: pointer;

                        &::before {
                            content: ' ';
                            position: absolute;
                            display: block;

                            bottom: 0;
                            width: 100%;
                            height: calc(var(--volume) * 100%);

                            background: white;
                        }
                    }
                }

                &:hover .volumeSliderContainer, &.adjusting .volumeSliderContainer {
                    display: block;
                }
            }

            .fullscreen {
                display: inline-block;

                .enter {
                    opacity: 1;
                    background-image: url('/icons/fullscreen.svg');
                }
                .exit {
                    opacity: 0;
                    background-image: url('/icons/unfullscreen.svg');
                }

                &.active {
                    .enter {
                        opacity: 0
                    }
                    .exit {
                        opacity: 1;
                    }
                }
            }
        }
    }
}



.lyricsContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    cursor: default;

    .card {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.playerContainer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
}
